// src/SocketContext.js
import { createContext } from 'react';
import { io } from 'socket.io-client';

const socket = io('https://wss.inet4g.co.ua'); // Создаем экземпляр сокета
// const socket = io('http://localhost:3000'); // Создаем экземпляр сокета

socket.on('connect', () => {
    console.log("+ Connected1")
});

socket.on('disconnect', () => {
    console.log("+ disconnect1")
});

const SocketContext = createContext(socket);

export { SocketContext, socket };