// import logo from './logo.svg';
// eslint-disable-next-line 
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import './App.css';
import Start from './components/Start';
import Clicker from './components/Clicker';

// const Clicker = React.lazy(() => import('./components/Clicker'));


function App() {
  return (
      <Router>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Routes>
            <Route path='/' element={<Start />}/>
            <Route path='/clicker' element={<Clicker />}/>
          </Routes>
        {/* </Suspense> */}
      </Router>
);
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         <img src={import("./logo.svg").then(logo => {return {logo}})} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
          
//         </a>
//       </header>
//     </div>
//   );
// }


export default App;
