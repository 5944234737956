import React, {useEffect, useState, useRef, useContext} from "react";
import { SocketContext } from '../SocketContext';
import { useLocation } from 'react-router-dom';
import './Clicker.css'


export default function Clicker() {
    const socket = useContext(SocketContext); 
    const location = useLocation();
    const user = location.state?.user;
    const [countClick, setCountClick] = useState(0)
    const [clicked, setClicked] = useState(false);
    const isTouching = useRef(false);
    const [showPlusOne, setShowPlusOne] = useState(false);

    useEffect(() => {

        if (user) {
            socket.emit('set_user', user);
        

            socket.on('data', (data) => {
                console.log('Click data received:', data);
                setCountClick(data);
            });

            socket.on('connect', () => {
                console.log("+ Connected")
                socket.emit('set_user', user);
            });

            socket.on('disconnect', () => {
                console.log("+ disconnect")
            });
        }

        return () => {
            socket.off('data');
            socket.off('connect');
            socket.off('disconnect');
        };
      }, [socket, user]);

      const handleAdd = (amount) => {
        console.log('Click event:', 1);
        socket.emit('click', {
          id: user.id,
          count: amount,
          timestamp: new Date().toLocaleString(),
        });
        setClicked(true);
       
    
        setTimeout(() => {
          setClicked(false);
        }, 10);

        setShowPlusOne(true);

        setTimeout(() => {
            setShowPlusOne(false);
        }, 500);
      };
    
      const handleClick = () => {
        if (!isTouching.current) {
          handleAdd(1);
        }
      };
    
      const handleTouchStart = (event) => {
        if (!isTouching.current){
          const touchCount = event.touches.length;
          handleAdd(touchCount);
          isTouching.current = true;
        }
      };
    
      const handleTouchEnd = () => {
        setTimeout(() => {
          isTouching.current = false;
        }, 75);
    };

    return (
        <div className="Clicker">
            {user ? (
                <div
                    className={`clickable-area ${clicked ? 'clicked' : ''}`}
                    onClick={handleClick}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    {showPlusOne && <div className="plus-one">+1</div>}
                    <p>{countClick}</p>
                </div>
            ) : (
                <div
                    className="clickable-area"
                >
                    <p>Don't Worry, Be Happy</p>
                </div>
            )}
        </div>
    );

    // console.log('Clicker');
    // return (
    //     <div className="Clicker">
    //         {user ? (
        
    //       <div
    //         className="clickable-area"
    //         style={{
    //           width: '100%',
    //           height: '100%',
    //         //   border: '1px solid black',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           fontSize: '24px',
    //           color: '#FFFFFF',
    //           cursor: 'pointer',
    //           backgroundColor: clicked ? '#444444' : '#282c34', // Изменение цвета фона при клике
    //         }}
    //         onClick={handleClick}
    //         onTouchStart={handleTouchStart}
    //         onTouchEnd={handleTouchEnd}
    //       >
    //         {/* <p>Socket Connected: {socketConnected ? 'Yes ' : 'No '}</p> */}
    //         <p>{countClick}</p>
  
    //       </div>
    //       ) : (<div
    //         className="clickable-area"
    //         style={{
    //           width: '100%',
    //           height: '100%',
    //         //   border: '1px solid black',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //           fontSize: '24px',
    //           color: '#FFFFFF',
    //           cursor: 'pointer',
    //           backgroundColor: '#282c34', // Изменение цвета фона при клике
    //         }}
    //       ><p>Don't Worry, Be Happy</p></div>)}
    //     </div>
    //   );
}

