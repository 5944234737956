import React, {useEffect, useState} from "react";
import { useNavigate  } from 'react-router-dom';
import './Start.css'


export default function Start() {

    const [user, setUser] = useState(null);
    const [version, setVersion] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = true;
        script.onload = () => {
            console.log('script')
            const app = window.Telegram.WebApp;
            app.ready(); 
            app.expand();
            window.addEventListener("touchmove", (e) => e.preventDefault(), { passive: false });
            // window.scrollTo(0, 100);
            app.headerColor = '#282c34';
            app.isClosingConfirmationEnabled = true;
            app.enableClosingConfirmation = false;
            app.MainButton.hide();
            setUser(app.initDataUnsafe.user);
            setVersion(app.version);
        }

        script.onerror = () => {
            console.error('Error loading Telegram WebApp script');
          };

        document.body.appendChild(script);

        return () => {
            document.body.style.overflow = '';
          };
    }, []);


    useEffect(() => {
        if (user) {
          setTimeout(() => {
            // navigate('/clicker', {state: {user: {id:5}}});
            navigate('/clicker', {state: {user}});
          }, 3000);
        }
      }, [navigate, user]);


    console.log('Start');
    return (
        <div className="Start">
          <header className="Start-header">
            {user ? (
              <div>
                <h1>Welcome, {user.first_name}</h1>
                <p>Your Telegram username ID is: {user.username}</p>
                <p>Your Telegram User ID is: {user.id}</p>
                <p>Version API: {version}</p>
              </div>
            ) : (
              <h1>Loading...</h1>
            )}
          </header>
        </div>
      );;
}